@import "../../styles/_variables";

.alertStructure {
    //height: 50px;
    padding: 11px 10px 10px 35px;
    border-radius: 7.5px;
    width: 100%;
    margin: 0.75rem auto;
    height: auto !important;
}

.success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.info {
    color: #1b1e21;
    background-color: #d6d8d9;
    border-color: #c6c8ca;
}

.alertIcon {
    color: #00000036;
    position: absolute;
    margin-left: -23px;
    margin-top: 1.7px;
}