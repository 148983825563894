body {
  zoom: 100%;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#fontPdf {
  font-family: "Open Sans";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// style of accordion in ManageQuestionnaire component

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: none !important;
  position: relative;
  -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow-anchor: none;
  border-radius: 0;
}

// To minimalist scrollbar in all browser

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.ant-modal-content {
  box-shadow: none !important;
}