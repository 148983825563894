@import "../../styles/_variables";

.tabsContainer {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-bottom: 10px;
}

.tabsButton,
.tabsButtonNew {
  min-width: 172px;
  // padding: 0px 30px 0px 30px;
  font-size: 14px !important;
  line-height: 40px;
  justify-content: flex-start;
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: 8px;
}

.tabsButton {
  border: 1px solid;
  height: 3.5em;
}

.tabsButton:hover,
.tabsButtonSelected {
  border: 1px solid #ff521f;
  color: #ff521f;
  width: 7.5em;
  padding: 0.25em 1em;
  background: #fff;
  cursor: pointer;
}

.tabsContainerSecoundRow {
  display: flex;
  justify-content: flex-start;
  position: absolute;
  top: 6.5rem;
  gap: 20px;
  flex-wrap: wrap;
}

.archiveButton {
  background: none;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  background-color: $red800;
}

.archiveButton:hover {
  background-color: $red900;
}

.archiveIcon {
  color: $white;
  vertical-align: middle;
  margin-left: 5px;
  margin-right: 5px;
}

.loading {
  color: $cyan500;
  text-align: center;
  margin-top: 150px;
}

.table {
  display: block;
  height: 100%;
  overflow-y: scroll;
  border-collapse: collapse;
  border-top: 1px solid rgb(200, 200, 200);
  width: 100%;
  border-right: none;
  border-left: none;
  border-bottom: none;
}

.tableTitle {
  text-align: left;
  padding-left: 30px;
}

.tableRow {
  height: 50px;
}

.itemRow {
  color: $gray400;
  padding-left: 20px;
  padding-right: 10px;
}

.itemTitle {
  font-weight: 600;
  padding-left: 30px;
}

.actionsContainer {
  text-align: center;
}

.editItemButton {
  color: $cyan500;
  cursor: pointer;
}

.archiveItemButton {
  color: $red800;
  cursor: pointer;
}

.arrowsDownUp {
  position: relative;
  top: 2.5px;
  color: $cyan500;
  cursor: pointer;
}

.helpPopupEdit,
.helpPopupArchive {
  display: none;
  position: absolute;
  font-size: 14px;
  background-color: black;
  border-radius: 3px;
  color: $white;
  padding-left: 2px;
  padding-right: 2px;
  margin-left: 3%;
}

.helpPopupArchive {
  margin-left: 0;
}

.editItemButton:hover~.helpPopupEdit,
.archiveItemButton:hover~.helpPopupArchive {
  display: block;
}

.primaryButtonDisabled {
  background: $gray300;
  border: 2px solid $gray400;
}

.alignCenter {
  text-align: center;
}

.imagePreview {
  width: 30%;
}

.colorDot {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.overlayOff {
  position: fixed;
  display: none;
}

.newItemPopUp {
  width: 330px;
  height: fit-content;
  border-radius: 7px;
  padding: 15px;
  position: absolute;
  z-index: 3;
  background-color: white;
  overflow: auto;
  top: 25%;
  display: block;
  padding-bottom: 0;
  text-align: start;
  margin-left: 20%;
}

.header {
  font-weight: 600;
  border-bottom: 1px solid rgb(177, 177, 177);
}

.popupRow {
  padding-top: 15px;
}

.popupFooter {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 15px;
}

.tabsButtonFooter {
  height: auto !important;
  font-size: 12px !important;
  min-width: 80px;
}

.imageContainer {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.colorContainer {
  display: flex;
  gap: 10px;
}

.colorPreviewContainer {
  width: 62px;
  height: 32px;
  border: 2px solid $gray200;
  cursor: pointer;
  background: $gray100;
  display: flex;
  justify-content: center;
}

.colorPreview {
  width: 50px;
  height: 20px;
  cursor: pointer;
  background: white;
  margin: 4px;
}

.colorCodeField {
  width: 40%;
}