@import "../../styles/_variables";

.smallMenu {
  //background-color: $cyan500;
  background-image: linear-gradient(#008ae0, #0064a2);
  box-shadow: 0rem 0rem 2rem 0.5rem rgba(0, 0, 0, 0.2);
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: 3;
  width: 75px;
  display: grid;
  grid-template-rows: auto 2fr;
  justify-content: center;
  min-height: 133.3vh;
}

.smallMenuItems {
  position: sticky;
  top: 0;
  height: fit-content;
}

.smallMenuImg {
  text-align: center;

  >img {
    width: 32px;
    filter: $whiteSVG;
    cursor: pointer;
    margin: 15px;
  }
}