@import "../../styles/_variables";



@font-face {
    font-family: "Open Sans";
    font-weight: bold;
    font-style: normal;
    src: url("../../fonts/open-sans/OpenSans-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Open Sans";
    font-weight: "extrabold";
    font-style: normal;
    src: url("../../fonts/open-sans/OpenSans-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    src: url("../../fonts/open-sans/OpenSans-Regular.ttf") format("truetype");
}


.spanDefaultReport {
    font-weight: normal;
}

th {
    font-weight: normal;
}

.reportContainer {
    width: 90%;
}

.headerInfo {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin: 1rem auto;
    padding: 2rem;
    min-height: 5rem;
    width: 100%;
    background-color: $gray100;
    border: 1px solid $gray200;
    border-radius: 1rem;
    font-size: 1.0rem;
}

.headerNoInfo {
    margin: 4rem auto 2rem auto;
    min-height: 1rem;
    width: 97%;
    background-color: $gray100;
    border: 1px solid $gray200;
    border-radius: 1rem;
}

.academicInfo {
    grid-area: 1 / 1 / 2 / 2;
}

.casesInfo {
    grid-area: 1 / 2 / 2 / 3;
}

.casesInfoText {
    margin-left: 1rem;
}

.casesInfoText::before {
    content: "•";
    margin-right: 0.25rem;
}

.loading {
    color: $cyan500;
    text-align: center;
    margin-top: 150px;
}

.errorMessage {
    color: $cyan500;
    text-align: center;
    margin-top: 150px;
}

.infoBlockTitle {
    font-size: 1.2rem;
    font-weight: 700;
}

.infoBlockLine {
    font-size: 1.0rem;
    font-weight: 500;
}

.infoBlock {
    margin-top: 2rem;
    display: grid;
    //grid-template-columns: repeat(2, 1fr);
    //grid-template-rows: repeat(4, 1fr);
    //grid-column-gap: 10rem;
    //grid-row-gap: 0px;
}

.infoBlockLine1 {
    grid-area: 1 / 1 / 2 / 2;
}

.infoBlockLine2 {
    grid-area: 2 / 1 / 3 / 2;
}

.infoBlockLine3 {
    grid-area: 3 / 1 / 4 / 2;
}

.infoBlockLine4 {
    text-align: center;
    color: $gray400;
    grid-area: 4 / 2 / 4 / 3;
}

.logoContainer {
    display: flex;
    justify-content: space-evenly;
    grid-area: 1 / 2 / 3 / 2;
}

.logoSize {
    height: 3rem;
}

.OnelogoSize {
    height: 6rem;
}

.infoBlockLine5 {
    //background: $gray400;
    grid-area: 4 / 1 / 4 / 1;
    //text-align: center;
    display: flex;
    //align-items: center;
    //justify-content: center;
    //border-radius: 1.5rem;
    //color: white;
}

.infoBlockBar_0 {
    background: $gray400;
    padding: 0.5rem;
    text-align: center;
    border-radius: 0.8rem;
    color: white;
    //max-height: 20%;
}

.customListContainer {
    display: block;
    position: relative;
    margin-top: 10px;
    //vertical-align: top;
}

.customList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.customList::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
}

.blocksBody {
    padding: 0px;
}

.spanAcademicInfo {
    display: block;
    margin: 0.5rem;
}

.infoLine {
    display: block;
    margin-bottom: 0.25rem;
    max-width: 75%;
}


.labelQuestion {
    margin-top: 1rem;
    color: $gray400;
    font-weight: 700;
    font-size: 1.25rem;
    padding: 1rem 1rem 0 1rem;
}

.questionTitle {
    margin-top: 1rem;
    color: $gray400;
    font-weight: 700;
    font-size: 1.25rem;
    padding: 1rem 1rem 0 1rem;
}

.labelBold {
    font-weight: 700;
    display: block;
    margin-top: 0.5rem;
    font-size: 1.1rem;
}

.labelRegular {
    font-weight: 400;
    display: block;
    margin-top: 0.5rem;
}

.textBold {
    max-width: 75%;
    display: block;
    font-weight: 700;
}

.text {
    display: block;
    font-weight: 400;
    margin-top: 0.1rem;
    font-size: 1.0rem;
}

.parentRating {
    margin-top: 25px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 15px;
    border-radius: 5px;
    text-align: left;
}

.groupedQuestion.oddBackground {
    background-color: $gray100;
    //border-radius: 1rem;
    /* Background color for odd groupedQuestion elements */
}

.groupedQuestion.regularLine {
    margin: 0.25rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.groupedQuestion.oddLine {
    background-color: $gray100;
    margin: 0.25rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.result {
    padding: 0.5rem;
    margin-right: 1rem;
}

.groupOfQuestions {
    padding-left: 0%;
    margin-top: 0.5rem;
}

.starsContainer {
    display: flex;
}

.starsContainer>div {
    display: inline-block;
    margin-right: 10px;
}

.trDefaultReport:nth-child(odd) {
    border-radius: 0% !important;
    background-color: #f2f2f200;
}

table {
    text-align: center;
    margin-left: auto;
}

.starPosition {
    margin-left: 0.25rem;
}

.ratingBox {
    margin-left: auto;
}

.npsContainer {
    display: flex;
    gap: 4rem;
    margin: 0 auto;
    align-items: center;
    width: max-content;
}

.npsScore {
    margin-left: 55px;
}

.npsInfo {
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    gap: 0.5rem;
}

.npsResult {
    font-weight: 700;
    margin-right: 0.25rem;
}

.npsLabel {
    margin-right: 0.5rem;
}

.npsGapColumn {
    display: flex;
    gap: 1rem;
    flex-direction: column;
}

.npsRow {
    display: flex;
    justify-content: space-between;
    width: -webkit-fill-available;
    align-items: center;
}

.npsResultLabel {
    display: flex;
}

.npsCardDetractor {
    display: flex;
    flex-direction: column;
    margin: 0 5rem;
    border: 1px solid #ECB5B2;
    background-color: #ECB5B2;
    width: 13rem;
    height: 9rem;
    border-radius: 1rem;
}

.npsHeaderDetractor {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    display: flex;
    height: 1.5rem;
    background-color: #EF6D81;
}

.npsCardPassive {
    display: flex;
    flex-direction: column;
    margin: 0 5rem;
    border: 1px solid #F4EDD4;
    background-color: #F4EDD4;
    width: 13rem;
    height: 9rem;
    border-radius: 1rem;
}

.npsHeaderPassive {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    display: flex;
    height: 1.5rem;
    background-color: #FDD275;
}

.npsCardPromoter {
    display: flex;
    flex-direction: column;
    margin: 0 5rem;
    border: 1px solid #D4F9D3;
    background-color: #D4F9D3;
    width: 13rem;
    height: 9rem;
    border-radius: 1rem;
}

.npsHeaderPromoter {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    display: flex;
    height: 1.5rem;
    background-color: #C7ECC6;
}

.promoter {
    margin: 0.25rem 0.5rem 0.25rem 0;
    min-width: 4.5rem;
    justify-content: center;
    display: inline-flex;
    flex-direction: row;
    padding: 0.25rem;
    background: #2EAA71;
    border-radius: 2rem;
}

.passive {
    margin: 0.25rem 0.5rem 0.25rem 0;
    min-width: 4.5rem;
    justify-content: center;
    display: inline-flex;
    flex-direction: row;
    padding: 0.25rem;
    background: #FDD275;
    border-radius: 2rem;
}

.detractor {
    margin: 0.25rem 0.5rem 0.25rem 0;
    min-width: 4.5rem;
    justify-content: center;
    display: inline-flex;
    flex-direction: row;
    padding: 0.25rem;
    background: #EF6D81;
    border-radius: 2rem;
}

.npsIcon {
    position: relative;
    bottom: 1rem;
    left: 0.5rem;
    width: 4rem;
    height: 4rem;
}

.spaceBeforeContent {
    margin: 2rem 2.5%;
    display: block;
}

.spaceBeforeTextArea {
    padding: 1.25rem;
    margin-left: 1rem;
}

.subItem {
    display: inline-flex;
    max-width: 175px;
    min-width: 120px;
    margin-left: 1rem;
}

.subItemResult {
    margin-left: 55px;
}

.questionHeaderText {
    background-color: transparent;
    text-align: left;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 15px;
}

.questionHeaderLeft {
    background-color: $gray100;
    text-align: left;
    color: $gray400;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    margin-bottom: 0.5rem;
}

.headerLeftLabel {
    padding: 1rem;
    font-size: 1.25rem;
}

.questionHeaderRight {
    background-color: #696a6b;
    color: #ffffff;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    margin-bottom: 0.5rem;
}

.questionHeaderDefault {
    background-color: #696a6b;
    color: #ffffff;
    margin-bottom: 0.5rem;
}



thead {
    background-color: transparent;
}

/* popup */

.transparent {
    position: absolute;
    top: 5%;
    right: 15%;
    background-color: transparent;
    border: none;
}

.overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
}

.overlayOff {
    position: fixed;
    display: none;
}

.optionsButton {
    display: flex;
    margin: 0.5rem 26% -2.5rem 0;
    justify-content: flex-end;
}

.newItemPopUp {
    width: 13rem;
    border-radius: 7px;
    padding: 15px;
    position: absolute;
    z-index: 3;
    background-color: white;
    height: 7rem;
    overflow: auto;
    top: 9em;
    right: 15%;
    display: block;
    padding-bottom: 0;
    text-align: start;
}

.popUpDivisor {
    margin: 0.4rem 0;
    border-bottom: 1px solid;
}

.titlePopUp {
    font-size: 1.25rem;

}

.optionsPopUp {
    height: 1.75rem;

    &:hover {
        text-align: start;
        background-color: $gray200;
        width: 100%;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
    }
}

.container {
    position: relative;
    height: 220px;
    margin: 60px;

    &::after {
        display: block;
        content: " ";
        color: transparent;
        border-right: 0.25rem solid $gray100;
        height: inherit;
        position: relative;
        left: 4.5rem;
        bottom: 15rem;
    }
}

.innerCircle {
    width: 170px;
    height: 170px;
    background-color: #ffffff;
    border-radius: 100%;
}

.circleContainer {
    position: relative;
    width: 200px;
    height: 200px;
    margin: 20px auto;
    border-radius: 100%;
    //background: conic-gradient(#FEB44D 0deg, #FEB44D 64deg,
    //        #2EAA71 64deg, #2EAA71 180deg,
    //        #E64857 180deg, #E64857 270deg);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
}

.point {
    position: absolute;
    width: 0px;
    height: 0px;
    transform: translate(-50%, -50%);
}

.px {
    width: 0;
    height: 0;
    z-index: 9999;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 16px solid black;
    transform: translate(-50%, -50%);
}

.rectangle {
    width: 164px;
    height: 40px;
    background-color: #ffffff;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 5px;
    box-sizing: border-box;
}

.rectangle span {
    position: absolute;
    bottom: 100%;
    white-space: nowrap;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
}

.rectangle span:first-child {
    top: -15px;
    left: -40px;
}

.rectangle span:last-child {
    top: -15px;
    right: -33px;
}

.centerText {
    position: absolute;
    font-size: 60px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.topLabel {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translate(-50%, -100%);
    z-index: 10;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.report {
    align-items: center !important;
    //margin: auto !important;
    font-size: 9pt;
    padding-top: 5%;
}


.tb_header_description {
    background-color: #696a6b;
    text-align: left;
    color: #ffffff;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    margin-bottom: 0.5rem;
    font-size: 1.2rem !important;
    font-weight: normal !important;
}

.pdf_header {
    background-color: #696a6b;
}

.pdf_footer {
    background-color: #0062a4;
}


.pdf_container {
    //margin-top: 5% !important;
    margin-left: 8% !important;
    margin-right: 8% !important;
}



.chart_rectangle {
    width: 100%;
    height: 3.0rem;
    background-color: #ffffff;
    position: absolute;
    margin-top: -3.9rem;
}

.chart_centerText {
    //position: absolute;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%, -50%);
    text-align: center;
    align-content: center;
    margin-top: 15%;
    font-size: 60px;
    z-index: 10;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
}