@import "../../styles/_variables";

.FormContainer {
  background-color: $gray100;
  border-radius: 16pt;
  font-size: 18px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
  /*  This stuff bellow blow up the layout  */
  //max-height: 580px;
  //overflow-x: hidden;
}

@media only screen and (max-width: 600px) {
  .FormContainer {
    margin: 2rem auto;
    padding: 20px;
    width: 90%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
  }
}

@media only screen and (min-width: 600px) {
  .FormContainer {
    margin: 2rem auto;
    padding: 30px;
    width: 75%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
  }
}

@media only screen and (min-width: 1200px) {
  .FormContainer {
    margin-left: auto;
    margin-right: auto;
    padding: 30px;
    position: relative;
    width: 70% !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
  }
}

@media only screen and (min-width: 1700px) {
  .FormContainer {
    margin-left: auto;
    margin-right: auto;
    padding: 30px;
    position: relative;
    width: 1000px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
  }
}