@import "../../styles/_variables";

.surveyHome {
  display: grid;
  justify-content: center;
  grid-column-start: 2;
  grid-row-start: 2;
  grid-row-end: 2;
}

.surveyTitle {
  font-weight: 600;
  text-align: center;
  font-size: 1.4rem;
  color: $cyan500;
  padding-top: 15%;
}

.surveyDescription {
  font-weight: 600;
  text-align: center;
  font-size: 1.2rem;
  color: $gray300;
  padding-top: 2%;
}

.surveyFieldContainer {
  padding-top: 10%;
}

.surveyFieldLabel {
  font-size: 1.1rem;
  color: $gray300;
  padding-bottom: 2%;
  text-align: center;
}

.searchIcon {
  position: absolute;
  display: inline-block;
  margin-top: 5px;
  margin-left: 5px;
  z-index: 1;
  color: $cyan500;
  vertical-align: middle;
  cursor: pointer;
}

.surveyField {
  border: 1px solid #ff8966;
  border-radius: 8px;
  height: 3em;
  //width: 28em;
  width: 100%;
  //padding-left: 40px;
}

.surveyButton {
  text-align: center;
  padding-top: 6%;
}

.surveyCamera {
  height: 100vh;
  width: 100vw;
}

.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.overlayOff {
  position: fixed;
  display: none;
}

.newItemPopUp {
  width: 330px;
  border-radius: 7px;
  padding: 15px;
  position: absolute;
  z-index: 3;
  background-color: white;
  max-height: 400px;
  overflow: auto;
  top: 25%;
  display: block;
  padding-bottom: 0;
  text-align: start;
  margin-left: 10%;
}

.header {
  font-weight: 600;
  border-bottom: 1px solid rgb(177, 177, 177);
}

.popupRow {
  padding-top: 15px;
}

.adminArea {
  cursor: pointer;
  position: absolute;
  right: 6rem;
  top: 1rem;
  color: $cyan500;
  text-decoration: underline;
}



@media only screen and (max-width: 768px) {
  .adminArea {
    right: 1rem;
    top: 80vh;
  }
}

@media only screen and (max-width: 415px) {
  //.surveyField {
  //  width: 100%;
  //}
}

@media only screen and (max-width: 360px) {
  //.surveyField {
  //  width: 20em;
  //}

  .surveyFieldLabel {
    font-size: 1rem;
  }
}