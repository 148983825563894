@import "../../styles/_variables";

.expandedMenu {
  //background-color: $cyan500;
  background-image: linear-gradient(#0078c2, #0064a2);
  box-shadow: 0rem 0rem 2rem 0.5rem rgba(0, 0, 0, 0.2);
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: 3;
  width: 270px;
  display: grid;
  grid-template-rows: auto 2fr;
  //min-height: 133.3vh;
}

.expandedMenuImg {
  text-align: center;
  width: fit-content;

  >img {
    width: 32px;
    filter: $whiteSVG;
    cursor: pointer;
    margin: 15px;
  }
}

.expandedMenuHeader {
  text-align: center;
  display: flex;
  position: fixed;
  top: 0;
  z-index: 1;
  //background-color: $cyan500;
}

.expandedMenuUser {
  margin-left: 20px;
}

.expandedMenuItems {
  margin: 15px;
  height: fit-content;
  position: fixed;
  top: 140px;
  width: 15rem;
}