@import "../../styles/_variables";

//All classes was found in Amplify component documentation
// https://ui.docs.amplify.aws/react/connected-components/authenticator/customization
  

.buttonWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: -moz-fit-content;
  width: fit-content;
  height: 200px;
  display: flex; /* Changed to flex to keep alignment */
}


.title {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: $orange100; // Adjust this color to your design
}

.basicLogin{
  padding: 10px 20px;   
  border-radius: 5px;
  height:47px;
  font-size: 16px;
  cursor: pointer;
  width:325px;
  margin-bottom:20px;
}



[data-amplify-authenticator][data-variation=modal] {
  background: transparent !important;
}
 

.amplify-button--primary {
  background-color: $orange100 !important;
}

.amplify-input {
  border: 2px solid $orange100 !important;
}

.amplify-field-group__outer-end {
  border: 2px solid $orange100 !important;
  border-inline-start: 0 !important;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.amplify-button {
  border: 0;
}

.amplify-tabs-item {
  color: $orange100 !important;
}

.amplify-tabs-item:hover {
  color: $orange300 !important;
}


[data-amplify-authenticator] [data-amplify-router] {
  box-shadow: 0px 0px 6px 4px rgba(211, 211, 211, 1);
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

.amplify-tabs {
  border-color: transparent !important;
}

.amplify-tabs-item {
  border-color: transparent !important;
}

[data-amplify-authenticator] [data-state=inactive] {
  color: $gray400 !important;
  display: none;
}

[data-amplify-authenticator] [data-amplify-router] {
  border-color: 0;
  border-width: 0;
  border-style: none;
}


.loginId {
  display: none;
}

.azuressobuttonwrapper { 
  margin-bottom:10px;
  text-align: center;
}

[data-amplify-authenticator]{
  max-width: 100%; 
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.azuressobutton {
  padding: 10px 20px;
  background-color: #0072c6;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer; 
  margin-bottom:10px !important;
}

.azuressobutton:hover {
  background-color: #005a9e;
}
