@import "../../styles/_variables";

//All classes was found in Amplify component documentation
// https://ui.docs.amplify.aws/react/connected-components/authenticator/customization
 

[data-amplify-authenticator][data-variation=modal] {
  background: transparent !important;
}
 

.amplify-button--primary {
  background-color: $orange100 !important;
}

.amplify-input {
  border: 2px solid $orange100 !important;
}

.amplify-field-group__outer-end {
  border: 2px solid $orange100 !important;
  border-inline-start: 0 !important;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem; // Adjust size as needed
  color: #333; // Customize color as needed
  cursor: pointer;
  position: absolute;
  top: 28%;
  right: 35.5%;
  z-index: 1;
}

.close-button:hover {
  color: $orange100; // Customize hover color as needed
}



.amplify-button {
  border: 0;
}

.amplify-tabs-item {
  color: $orange100 !important;
}

.amplify-tabs-item:hover {
  color: $orange300 !important;
}


[data-amplify-authenticator] [data-amplify-router] {
  box-shadow: 0px 0px 6px 4px rgba(211, 211, 211, 1);
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

.amplify-tabs {
  border-color: transparent !important;
}

.amplify-tabs-item {
  border-color: transparent !important;
}

[data-amplify-authenticator] [data-state=inactive] {
  color: $gray400 !important;
  display: none;
}

[data-amplify-authenticator] [data-amplify-router] {
  border-color: 0;
  border-width: 0;
  border-style: none;
}


.loginId {
  display: none;
}
 

[data-amplify-authenticator]{
  max-width: 100%; 
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
 