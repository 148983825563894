@import "../../styles/_variables";

.buttonsContainer {
  display: inline-flex;
  gap: 10px;
}

.createCollectionButton {
  padding: 0px 30px 0px 30px;
  font-size: 14px !important;
  line-height: 40px;
}

.archiveButton {
  background: none;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  background-color: $red800;
}

.permissionPopup {
  width: 330px;
  border-radius: 7px;
  padding: 15px;
  position: absolute;
  z-index: 3;
  background-color: white;
  height: 500px;
  overflow: auto;
  top: 15%;
  display: block;
  padding-bottom: 0;
  text-align: start;
  margin-left: 20%;
}

.archiveIcon {
  color: $white;
  vertical-align: middle;
  margin-left: 5px;
  margin-right: 5px;
}

.archiveButton:hover {
  background-color: $red900;
}

.addIcon {
  color: $white;
  vertical-align: middle;
  margin-right: 3px;
}


.loading {
  color: $cyan500;
  text-align: center;
  margin-top: 150px;
}

.table {
  display: block;
  height: 100%;
  overflow-y: scroll;
  border-collapse: collapse;
  border-top: 1px solid rgb(200, 200, 200);
  width: 100%;
  border-right: none;
  border-left: none;
  border-bottom: none;
}

.tableTitle {
  text-align: left;
  padding-left: 30px;
}

.tableRow {
  height: 50px;
}

.alignCenter {
  text-align: center;
}

.collectionRow {
  color: $gray400;
  padding-left: 20px;
  padding-right: 10px;
}

.collectionTitle {
  font-weight: 600;
  padding-left: 30px;
}

.actionsContainer {
  text-align: center;
}

.editCollectionButton,
.moreActionsButton,
.copyCollectionButton {
  color: $cyan500;
  cursor: pointer;
}

.moreActionsPopup {
  width: 150px;
  border-radius: 7px;
  padding: 15px;
  position: absolute;
  margin-left: 11%;
  z-index: 3;
  background-color: white;
  margin-top: -50px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.permissionUserData {
  display:block;
  padding-left: 10px;
}

.permissionUserPhoto {
  display: block;
}

.accessGrantedDate{
  font-size:12px;
}

.accessList { 
  min-height:250px;
  max-height: 250px;
  overflow: scroll;
  margin-top: 10px;
}

.removePermission{
  font-size:12px;
  cursor:pointer;
}

.userInformation {
  display: inline-flex;
  margin-top:25px;
  line-height: 16px;
}


.popupRow {
  padding-top: 15px;
}

.overlayOff {
  position: fixed;
  display: none;
}

.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.popupFooter {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 15px;
} 

.tabsButtonFooter {
  height: auto !important;
  font-size: 12px !important;
  min-width: 80px;
  margin-top:10px;
}

.header {
  font-weight: 600;
  border-bottom: 1px solid rgb(177, 177, 177);
}

.popupHeader {
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid rgb(177, 177, 177);
}

.popupOption {
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.popupOption:hover {
  background-color: $gray200;
  border-radius: 7px;
  cursor: pointer;
}

.helpPopupEdit,
.helpPopupCopy,
.helpPopupAction {
  display: none;
  position: absolute;
  font-size: 14px;
  background-color: black;
  border-radius: 3px;
  color: $white;
  padding-left: 2px;
  padding-right: 2px;
  margin-left: 130px;
}

.copyCollectionButton:hover~.helpPopupCopy,
.moreActionsButton:hover~.helpPopupAction,
.editCollectionButton:hover~.helpPopupEdit {
  display: block;
}

.arrowsDownUp {
  position: relative;
  top: 2.5px;
  color: $cyan500;
  cursor: pointer;
}

.tabsButtonFooter {
  height: auto !important;
  font-size: 12px !important;
  min-width: 80px;
}

.closeMoreActions {
  color: $gray400;
  cursor: pointer;
  position: absolute;
  top: 0.35rem;
  right: 0.35rem;
}

.moreActionsButton:hover {
  background-color: $gray200;
  border-radius: 7px;
}