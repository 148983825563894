@import "../../../styles/_variables";

.buttonsContainer {
  display: inline-flex;
  gap: 10px;
}

.createAssessmentButton {
  padding: 0px 30px 0px 30px;
  font-size: 14px !important;
  line-height: 40px;
}

.archiveButton {
  background: none;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  background-color: $red800;
}

.archiveIcon {
  color: $white;
  vertical-align: middle;
  margin-left: 5px;
  margin-right: 5px;
}

.archiveButton:hover {
  background-color: $red900;
}

.addIcon {
  color: $white;
  vertical-align: middle;
  margin-right: 3px;
}


.loading {
  color: $cyan500;
  text-align: center;
  margin-top: 150px;
}

.actionsContainer {
  text-align: center;
}

.table {
  display: block;
  height: 100%;
  overflow-y: scroll;
  border-collapse: collapse;
  border-top: 1px solid rgb(200, 200, 200);
  text-align: center;
  width: 100%;
  border-right: none;
  border-left: none;
  border-bottom: none;
}

.tableRow {
  height: 50px;
}

.alignLeft {
  text-align: left;
}

.assessmentRow {
  color: $gray400;
}

.assessmentTitle {
  font-weight: 600;
  padding-left: 30px;
}

.arrowsDownUp {
  position: relative;
  top: 2.5px;
  color: $cyan500;
  cursor: pointer;
}

.editAssessmentButton,
.moreActionsButton {
  color: $cyan500;
  cursor: pointer;
}

.previewAssessmentButton {
  color: $green500;
  cursor: pointer;
}

.pptButton {
  color: $orange100;
}


.helpPopupEdit,
.helpPopupPreview,
.helpPopupAction,
.helpPopupPPT {
  display: none;
  position: absolute;
  font-size: 14px;
  background-color: black;
  border-radius: 3px;
  color: $white;
  padding-left: 2px;
  padding-right: 2px;
  margin-left: 70px;
}

.previewAssessmentButton:hover~.helpPopupPreview,
.moreActionsButton:hover~.helpPopupAction,
.editAssessmentButton:hover~.helpPopupEdit,
.pptButton:hover~.helpPopupPPT {
  display: block;
}

.moreActionsPopup {
  width: 150px;
  border-radius: 7px;
  padding: 15px;
  position: absolute;
  margin-left: 8%;
  z-index: 3;
  background-color: white;
  margin-top: -50px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.popupHeader {
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid rgb(177, 177, 177);
}

.popupOption {
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.popupOption:hover {
  background-color: $gray200;
  border-radius: 7px;
  cursor: pointer;
}

.rowTitle {
  text-align: left;
  padding-left: 20px;
  padding-right: 10px;
}

.closeMoreActions {
  color: $gray400;
  cursor: pointer;
  position: absolute;
  top: 0.35rem;
  right: 0.35rem;
}

.moreActionsButton:hover {
  background-color: $gray200;
  border-radius: 7px;
}