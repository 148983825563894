@import "../../../styles/_variables";

.funnelContainer {
  position: absolute;
  margin-top: -29px;
  right: -2%;
}

.funnelIcon {
  margin-left: 10px;
  color: $cyan500;
  cursor: pointer;
}

.funnelIconActivate {
  margin-left: 10px;
  color: white !important;
  background-color: #0062a4;
  border-radius: 50% !important;
  cursor: pointer;
}

.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.overlayOff {
  position: fixed;
  display: none;
}

.filterPopUp {
  //width: 100vw;
  //border-radius: 7px;
  //padding: 15px;
  //position: absolute;
  //margin-left: 6%;
  //z-index: 3;
  margin: 10%;
  background-color: white;
  border-radius: 0.3rem;
  //top: 0;
  //right: 0;
}

.header {
  text-align: left;
  margin: 2%;
  font-weight: 600;
  //border-bottom: 1px solid rgb(177, 177, 177);
}

.cleanFilter {
  text-align: right;
  margin: 2%;
  //position: absolute;
  //margin-top: -20px;
  //margin-left: auto !important;
  ///display: block;
  //right: 20px;
  font-weight: 400;
  color: $orange100;
  cursor: pointer;
}

.filterBody {
  margin-top: 5px;
  display: flex;
  column-gap: 5%;
  flex-wrap: wrap;
  row-gap: 30px;
  height: auto;
}

.rowTitle {
  margin: 0.5rem 0;
  font-weight: 600;
}

.firstRow {
  display: flex;
  gap: 5%;
  height: 4rem;
  flex-basis: 100%;
}

.optionsRow {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  overflow-x: hidden;
  height: 200px;
}

.itemRow {
  flex-basis: 30%;
}

.dateFieldRow {
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tabsButtonFooter {
  height: auto !important;
  font-size: 1rem !important;
  min-width: 120px;
  margin-top: 10px;
  padding: 0.25rem;
}

.popupFooter {
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
  gap: 1rem;
  padding-bottom: 15px;
}

@media only screen and (max-width: 1300px) {
  .funnelContainer {
    right: 5.5%;
  }
}

@media only screen and (max-width: 900px) {
  .funnelContainer {
    right: 1%;
  }
}