@import "../../styles/_variables";

.primaryOrange {
  background: #ff8966;
  color: #fff;
  width: auto;
  height: 3em;
  font-size: 1em;
  padding: 0.25em 1em;
  border: 2px solid #ff8966;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
}

.primaryOrange:hover {
  background: #ff521f;
  border: 2px solid #ff521f;
}

.secondaryOrange {
  background: #fff;
  color: #ff8966;
  width: auto;
  height: 3em;
  font-size: 1em;
  border: 2px solid #ff8966;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
}

.secondaryOrange:hover {
  border: 2px solid #ff521f;
  color: #ff521f;
}

.primaryBlue {
  background: #0064a2;
  color: #fff;
  width: 7.5em;
  height: 3em;
  font-size: 1em;
  padding: 0.25em 1em;
  border: 2px solid #0064a2;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
}

.primaryBlue:hover {
  background: #004776;
  border: 2px solid #004776;
}

.secondaryBlue {
  background: #fff;
  color: #0064a2;
  width: 7.5em;
  height: 3em;
  font-size: 1em;
  padding: 0.25em 1em;
  border: 2px solid #0064a2;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
}

.secondaryBlue:hover {
  border: 2px solid #004776;
  color: #004776;
}

.recentsSurveys {
  background: #ff8966;
  color: #fff;
  width: 24em;
  height: 3em;
  font-size: 1em;
  padding: 0.25em 1em;
  border: 2px solid #ff8966;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
}

.recentsSurveys:hover {
  background: #ff521f;
  border: 2px solid #ff521f;
}

.primaryRed {
  background: #e25858;
  color: #fff;
  width: 7.5em;
  height: 3em;
  font-size: 1em;
  padding: 0.25em 1em;
  border: 2px solid #e25858;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
}

.primaryRed:hover {
  background: #760000;
  border: 2px solid #760000;
}

.transparent {
  border: 0;
  background-color: transparent;
  cursor: pointer;
  display: block;
}

.deactivated {
  background: #7e77772c;
  color: #b6b6b6;
  width: 8rem;
  height: 2.5rem;
  font-size: 1em;
  padding: 0.25em 1em;
  border: 2px solid #7e777734;
  border-radius: 3rem;
  transition: 0.2s;
  cursor: not-allowed;
}

.sendButton {
  background: $gray400;
  color: $white;
  width: 8rem;
  height: 2.5rem;
  font-size: 1em;
  padding: 0.25em 1em;
  border: none;
  border-radius: 3rem;
  transition: 0.2s;
  cursor: pointer;
  transition: 0.2s;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
}

.sendButton:hover {
  background: $gray300;
}