$row-gutter-x: 1.5rem;
$row-gutter-y: 0;

@import "../../styles/_variables";

.m_0 {
    margin: 0 !important;
}
.m_1 {
    margin: 0.25rem !important;
}
.m_2 {
    margin: 0.5rem !important;
}
.m_3 {
    margin: 1rem !important;
}
.m_4 {
    margin: 1.5rem !important;
}
.m_5 {
    margin: 3rem !important;
}
.m_auto {
    margin: auto !important;
}
.mx_0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
}
.mx_1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
}
.mx_2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
}
.mx_3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
}
.mx_4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
}
.mx_5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
}
.mx_auto {
    margin-right: auto !important;
    margin-left: auto !important;
}
.my_0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.my_1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
}
.my_2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}
.my_3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}
.my_4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}
.my_5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}
.my_auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}
.mt_0 {
    margin-top: 0 !important;
}
.mt_1 {
    margin-top: 0.25rem !important;
}
.mt_2 {
    margin-top: 0.5rem !important;
}
.mt_3 {
    margin-top: 1rem !important;
}
.mt_4 {
    margin-top: 1.5rem !important;
}
.mt_5 {
    margin-top: 3rem !important;
}
.mt_auto {
    margin-top: auto !important;
}
.me_0 {
    margin-right: 0 !important;
}
.me_1 {
    margin-right: 0.25rem !important;
}
.me_2 {
    margin-right: 0.5rem !important;
}
.me_3 {
    margin-right: 1rem !important;
}
.me_4 {
    margin-right: 1.5rem !important;
}
.me_5 {
    margin-right: 3rem !important;
}
.me_auto {
    margin-right: auto !important;
}
.mb_0 {
    margin-bottom: 0 !important;
}
.mb_1 {
    margin-bottom: 0.25rem !important;
}
.mb_2 {
    margin-bottom: 0.5rem !important;
}
.mb_3 {
    margin-bottom: 1rem !important;
}
.mb_4 {
    margin-bottom: 1.5rem !important;
}
.mb_5 {
    margin-bottom: 3rem !important;
}
.mb_auto {
    margin-bottom: auto !important;
}
.ms_0 {
    margin-left: 0 !important;
}
.ms_1 {
    margin-left: 0.25rem !important;
}
.ms_2 {
    margin-left: 0.5rem !important;
}
.ms_3 {
    margin-left: 1rem !important;
}
.ms_4 {
    margin-left: 1.5rem !important;
}
.ms_5 {
    margin-left: 3rem !important;
}
.ms_auto {
    margin-left: auto !important;
}
.p_0 {
    padding: 0 !important;
}
.p_1 {
    padding: 0.25rem !important;
}
.p_2 {
    padding: 0.5rem !important;
}
.p_3 {
    padding: 1rem !important;
}
.p_4 {
    padding: 1.5rem !important;
}
.p_5 {
    padding: 3rem !important;
}
.px_0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.px_1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
}
.px_2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
}
.px_3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}
.px_4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}
.px_5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}
.py_0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.py_1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}
.py_2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}
.py_3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}
.py_4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}
.py_5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}
.pt_0 {
    padding-top: 0 !important;
}
.pt_1 {
    padding-top: 0.25rem !important;
}
.pt_2 {
    padding-top: 0.5rem !important;
}
.pt_3 {
    padding-top: 1rem !important;
}
.pt_4 {
    padding-top: 1.5rem !important;
}
.pt_5 {
    padding-top: 3rem !important;
}
.pe_0 {
    padding-right: 0 !important;
}
.pe_1 {
    padding-right: 0.25rem !important;
}
.pe_2 {
    padding-right: 0.5rem !important;
}
.pe_3 {
    padding-right: 1rem !important;
}
.pe_4 {
    padding-right: 1.5rem !important;
}
.pe_5 {
    padding-right: 3rem !important;
}
.pb_0 {
    padding-bottom: 0 !important;
}
.pb_1 {
    padding-bottom: 0.25rem !important;
}
.pb_2 {
    padding-bottom: 0.5rem !important;
}
.pb_3 {
    padding-bottom: 1rem !important;
}
.pb_4 {
    padding-bottom: 1.5rem !important;
}
.pb_5 {
    padding-bottom: 3rem !important;
}
.ps_0 {
    padding-left: 0 !important;
}
.ps_1 {
    padding-left: 0.25rem !important;
}
.ps_2 {
    padding-left: 0.5rem !important;
}
.ps_3 {
    padding-left: 1rem !important;
}
.ps_4 {
    padding-left: 1.5rem !important;
}
.ps_5 {
    padding-left: 3rem !important;
}
.row {
    --bs-gutter-x: $row-gutter-x;
    --bs-gutter-y: $row-gutter-y;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
}
.col {
    flex: 1 0 0%;
}
.row_cols_auto > * {
    flex: 0 0 auto;
    width: auto;
}
.row_cols_1 > * {
    flex: 0 0 auto;
    width: 100%;
}
.row_cols_2 > * {
    flex: 0 0 auto;
    width: 50%;
}
.row_cols_3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
}
.row_cols_4 > * {
    flex: 0 0 auto;
    width: 25%;
}
.row_cols_5 > * {
    flex: 0 0 auto;
    width: 20%;
}
.row_cols_6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
}
.col_auto {
    flex: 0 0 auto;
    width: auto;
}
.col_1 {
    flex: 0 0 auto;
    width: 8.33333333%;
}
.col_2 {
    flex: 0 0 auto;
    width: 16.66666667%;
}
.col_3 {
    flex: 0 0 auto;
    width: 25%;
}
.col_4 {
    flex: 0 0 auto;
    width: 33.33333333%;
}
.col_5 {
    flex: 0 0 auto;
    width: 41.66666667%;
}
.col_6 {
    flex: 0 0 auto;
    width: 50%;
}
.col_7 {
    flex: 0 0 auto;
    width: 58.33333333%;
}
.col_8 {
    flex: 0 0 auto;
    width: 66.66666667%;
}
.col_9 {
    flex: 0 0 auto;
    width: 75%;
}
.col_10 {
    flex: 0 0 auto;
    width: 83.33333333%;
}
.col_11 {
    flex: 0 0 auto;
    width: 91.66666667%;
}
.col_12 {
    flex: 0 0 auto;
    width: 100%;
}
.offset_1 {
    margin-left: 8.33333333%;
}
.offset_2 {
    margin-left: 16.66666667%;
}
.offset_3 {
    margin-left: 25%;
}
.offset_4 {
    margin-left: 33.33333333%;
}
.offset_5 {
    margin-left: 41.66666667%;
}
.offset_6 {
    margin-left: 50%;
}
.offset_7 {
    margin-left: 58.33333333%;
}
.offset_8 {
    margin-left: 66.66666667%;
}
.offset_9 {
    margin-left: 75%;
}
.offset_10 {
    margin-left: 83.33333333%;
}
.offset_11 {
    margin-left: 91.66666667%;
}
.g_0,
.gx_0 {
    --bs-gutter-x: 0;
}
.g_0,
.gy_0 {
    --bs-gutter-y: 0;
}
.g_1,
.gx_1 {
    --bs-gutter-x: 0.25rem;
}
.g_1,
.gy_1 {
    --bs-gutter-y: 0.25rem;
}
.g_2,
.gx_2 {
    --bs-gutter-x: 0.5rem;
}
.g_2,
.gy_2 {
    --bs-gutter-y: 0.5rem;
}
.g_3,
.gx_3 {
    --bs-gutter-x: 1rem;
}
.g_3,
.gy_3 {
    --bs-gutter-y: 1rem;
}
.g_4,
.gx_4 {
    --bs-gutter-x: 1.5rem;
}
.g_4,
.gy_4 {
    --bs-gutter-y: 1.5rem;
}
.g_5,
.gx_5 {
    --bs-gutter-x: 3rem;
}
.g_5,
.gy_5 {
    --bs-gutter-y: 3rem;
}
@media (min-width: 576px) {
    .col_sm {
        flex: 1 0 0%;
    }
    .row_cols_sm_auto > * {
        flex: 0 0 auto;
        width: auto;
    }
    .row_cols_sm_1 > * {
        flex: 0 0 auto;
        width: 100%;
    }
    .row_cols_sm_2 > * {
        flex: 0 0 auto;
        width: 50%;
    }
    .row_cols_sm_3 > * {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
    .row_cols_sm_4 > * {
        flex: 0 0 auto;
        width: 25%;
    }
    .row_cols_sm_5 > * {
        flex: 0 0 auto;
        width: 20%;
    }
    .row_cols_sm_6 > * {
        flex: 0 0 auto;
        width: 16.66666667%;
    }
    .col_sm_auto {
        flex: 0 0 auto;
        width: auto;
    }
    .col_sm_1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }
    .col_sm_2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }
    .col_sm_3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .col_sm_4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
    .col_sm_5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }
    .col_sm_6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .col_sm_7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }
    .col_sm_8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }
    .col_sm_9 {
        flex: 0 0 auto;
        width: 75%;
    }
    .col_sm_10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }
    .col_sm_11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }
    .col_sm_12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .offset_sm_0 {
        margin-left: 0;
    }
    .offset_sm_1 {
        margin-left: 8.33333333%;
    }
    .offset_sm_2 {
        margin-left: 16.66666667%;
    }
    .offset_sm_3 {
        margin-left: 25%;
    }
    .offset_sm_4 {
        margin-left: 33.33333333%;
    }
    .offset_sm_5 {
        margin-left: 41.66666667%;
    }
    .offset_sm_6 {
        margin-left: 50%;
    }
    .offset_sm_7 {
        margin-left: 58.33333333%;
    }
    .offset_sm_8 {
        margin-left: 66.66666667%;
    }
    .offset_sm_9 {
        margin-left: 75%;
    }
    .offset_sm_10 {
        margin-left: 83.33333333%;
    }
    .offset_sm_11 {
        margin-left: 91.66666667%;
    }
    .g_sm_0,
    .gx_sm_0 {
        --bs-gutter-x: 0;
    }
    .g_sm_0,
    .gy_sm_0 {
        --bs-gutter-y: 0;
    }
    .g_sm_1,
    .gx_sm_1 {
        --bs-gutter-x: 0.25rem;
    }
    .g_sm_1,
    .gy_sm_1 {
        --bs-gutter-y: 0.25rem;
    }
    .g_sm_2,
    .gx_sm_2 {
        --bs-gutter-x: 0.5rem;
    }
    .g_sm_2,
    .gy_sm_2 {
        --bs-gutter-y: 0.5rem;
    }
    .g_sm_3,
    .gx_sm_3 {
        --bs-gutter-x: 1rem;
    }
    .g_sm_3,
    .gy_sm_3 {
        --bs-gutter-y: 1rem;
    }
    .g_sm_4,
    .gx_sm_4 {
        --bs-gutter-x: 1.5rem;
    }
    .g_sm_4,
    .gy_sm_4 {
        --bs-gutter-y: 1.5rem;
    }
    .g_sm_5,
    .gx_sm_5 {
        --bs-gutter-x: 3rem;
    }
    .g_sm_5,
    .gy_sm_5 {
        --bs-gutter-y: 3rem;
    }
}
@media (min-width: 768px) {
    .col_md {
        flex: 1 0 0%;
    }
    .row_cols_md_auto > * {
        flex: 0 0 auto;
        width: auto;
    }
    .row_cols_md_1 > * {
        flex: 0 0 auto;
        width: 100%;
    }
    .row_cols_md_2 > * {
        flex: 0 0 auto;
        width: 50%;
    }
    .row_cols_md_3 > * {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
    .row_cols_md_4 > * {
        flex: 0 0 auto;
        width: 25%;
    }
    .row_cols_md_5 > * {
        flex: 0 0 auto;
        width: 20%;
    }
    .row_cols_md_6 > * {
        flex: 0 0 auto;
        width: 16.66666667%;
    }
    .col_md_auto {
        flex: 0 0 auto;
        width: auto;
    }
    .col_md_1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }
    .col_md_2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }
    .col_md_3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .col_md_4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
    .col_md_5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }
    .col_md_6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .col_md_7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }
    .col_md_8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }
    .col_md_9 {
        flex: 0 0 auto;
        width: 75%;
    }
    .col_md_10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }
    .col_md_11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }
    .col_md_12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .offset_md_0 {
        margin-left: 0;
    }
    .offset_md_1 {
        margin-left: 8.33333333%;
    }
    .offset_md_2 {
        margin-left: 16.66666667%;
    }
    .offset_md_3 {
        margin-left: 25%;
    }
    .offset_md_4 {
        margin-left: 33.33333333%;
    }
    .offset_md_5 {
        margin-left: 41.66666667%;
    }
    .offset_md_6 {
        margin-left: 50%;
    }
    .offset_md_7 {
        margin-left: 58.33333333%;
    }
    .offset_md_8 {
        margin-left: 66.66666667%;
    }
    .offset_md_9 {
        margin-left: 75%;
    }
    .offset_md_10 {
        margin-left: 83.33333333%;
    }
    .offset_md_11 {
        margin-left: 91.66666667%;
    }
    .g_md_0,
    .gx_md_0 {
        --bs-gutter-x: 0;
    }
    .g_md_0,
    .gy_md_0 {
        --bs-gutter-y: 0;
    }
    .g_md_1,
    .gx_md_1 {
        --bs-gutter-x: 0.25rem;
    }
    .g_md_1,
    .gy_md_1 {
        --bs-gutter-y: 0.25rem;
    }
    .g_md_2,
    .gx_md_2 {
        --bs-gutter-x: 0.5rem;
    }
    .g_md_2,
    .gy_md_2 {
        --bs-gutter-y: 0.5rem;
    }
    .g_md_3,
    .gx_md_3 {
        --bs-gutter-x: 1rem;
    }
    .g_md_3,
    .gy_md_3 {
        --bs-gutter-y: 1rem;
    }
    .g_md_4,
    .gx_md_4 {
        --bs-gutter-x: 1.5rem;
    }
    .g_md_4,
    .gy_md_4 {
        --bs-gutter-y: 1.5rem;
    }
    .g_md_5,
    .gx_md_5 {
        --bs-gutter-x: 3rem;
    }
    .g_md_5,
    .gy_md_5 {
        --bs-gutter-y: 3rem;
    }
}
@media (min-width: 992px) {
    .col_lg {
        flex: 1 0 0%;
    }
    .row_cols_lg_auto > * {
        flex: 0 0 auto;
        width: auto;
    }
    .row_cols_lg_1 > * {
        flex: 0 0 auto;
        width: 100%;
    }
    .row_cols_lg_2 > * {
        flex: 0 0 auto;
        width: 50%;
    }
    .row_cols_lg_3 > * {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
    .row_cols_lg_4 > * {
        flex: 0 0 auto;
        width: 25%;
    }
    .row_cols_lg_5 > * {
        flex: 0 0 auto;
        width: 20%;
    }
    .row_cols_lg_6 > * {
        flex: 0 0 auto;
        width: 16.66666667%;
    }
    .col_lg_auto {
        flex: 0 0 auto;
        width: auto;
    }
    .col_lg_1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }
    .col_lg_2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }
    .col_lg_3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .col_lg_4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
    .col_lg_5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }
    .col_lg_6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .col_lg_7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }
    .col_lg_8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }
    .col_lg_9 {
        flex: 0 0 auto;
        width: 75%;
    }
    .col_lg_10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }
    .col_lg_11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }
    .col_lg_12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .offset_lg_0 {
        margin-left: 0;
    }
    .offset_lg_1 {
        margin-left: 8.33333333%;
    }
    .offset_lg_2 {
        margin-left: 16.66666667%;
    }
    .offset_lg_3 {
        margin-left: 25%;
    }
    .offset_lg_4 {
        margin-left: 33.33333333%;
    }
    .offset_lg_5 {
        margin-left: 41.66666667%;
    }
    .offset_lg_6 {
        margin-left: 50%;
    }
    .offset_lg_7 {
        margin-left: 58.33333333%;
    }
    .offset_lg_8 {
        margin-left: 66.66666667%;
    }
    .offset_lg_9 {
        margin-left: 75%;
    }
    .offset_lg_10 {
        margin-left: 83.33333333%;
    }
    .offset_lg_11 {
        margin-left: 91.66666667%;
    }
    .g_lg_0,
    .gx_lg_0 {
        --bs-gutter-x: 0;
    }
    .g_lg_0,
    .gy_lg_0 {
        --bs-gutter-y: 0;
    }
    .g_lg_1,
    .gx_lg_1 {
        --bs-gutter-x: 0.25rem;
    }
    .g_lg_1,
    .gy_lg_1 {
        --bs-gutter-y: 0.25rem;
    }
    .g_lg_2,
    .gx_lg_2 {
        --bs-gutter-x: 0.5rem;
    }
    .g_lg_2,
    .gy_lg_2 {
        --bs-gutter-y: 0.5rem;
    }
    .g_lg_3,
    .gx_lg_3 {
        --bs-gutter-x: 1rem;
    }
    .g_lg_3,
    .gy_lg_3 {
        --bs-gutter-y: 1rem;
    }
    .g_lg_4,
    .gx_lg_4 {
        --bs-gutter-x: 1.5rem;
    }
    .g_lg_4,
    .gy_lg_4 {
        --bs-gutter-y: 1.5rem;
    }
    .g_lg_5,
    .gx_lg_5 {
        --bs-gutter-x: 3rem;
    }
    .g_lg_5,
    .gy_lg_5 {
        --bs-gutter-y: 3rem;
    }
}
@media (min-width: 1200px) {
    .col_xl {
        flex: 1 0 0%;
    }
    .row_cols_xl_auto > * {
        flex: 0 0 auto;
        width: auto;
    }
    .row_cols_xl_1 > * {
        flex: 0 0 auto;
        width: 100%;
    }
    .row_cols_xl_2 > * {
        flex: 0 0 auto;
        width: 50%;
    }
    .row_cols_xl_3 > * {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
    .row_cols_xl_4 > * {
        flex: 0 0 auto;
        width: 25%;
    }
    .row_cols_xl_5 > * {
        flex: 0 0 auto;
        width: 20%;
    }
    .row_cols_xl_6 > * {
        flex: 0 0 auto;
        width: 16.66666667%;
    }
    .col_xl_auto {
        flex: 0 0 auto;
        width: auto;
    }
    .col_xl_1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }
    .col_xl_2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }
    .col_xl_3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .col_xl_4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
    .col_xl_5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }
    .col_xl_6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .col_xl_7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }
    .col_xl_8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }
    .col_xl_9 {
        flex: 0 0 auto;
        width: 75%;
    }
    .col_xl_10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }
    .col_xl_11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }
    .col_xl_12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .offset_xl_0 {
        margin-left: 0;
    }
    .offset_xl_1 {
        margin-left: 8.33333333%;
    }
    .offset_xl_2 {
        margin-left: 16.66666667%;
    }
    .offset_xl_3 {
        margin-left: 25%;
    }
    .offset_xl_4 {
        margin-left: 33.33333333%;
    }
    .offset_xl_5 {
        margin-left: 41.66666667%;
    }
    .offset_xl_6 {
        margin-left: 50%;
    }
    .offset_xl_7 {
        margin-left: 58.33333333%;
    }
    .offset_xl_8 {
        margin-left: 66.66666667%;
    }
    .offset_xl_9 {
        margin-left: 75%;
    }
    .offset_xl_10 {
        margin-left: 83.33333333%;
    }
    .offset_xl_11 {
        margin-left: 91.66666667%;
    }
    .g_xl_0,
    .gx_xl_0 {
        --bs-gutter-x: 0;
    }
    .g_xl_0,
    .gy_xl_0 {
        --bs-gutter-y: 0;
    }
    .g_xl_1,
    .gx_xl_1 {
        --bs-gutter-x: 0.25rem;
    }
    .g_xl_1,
    .gy_xl_1 {
        --bs-gutter-y: 0.25rem;
    }
    .g_xl_2,
    .gx_xl_2 {
        --bs-gutter-x: 0.5rem;
    }
    .g_xl_2,
    .gy_xl_2 {
        --bs-gutter-y: 0.5rem;
    }
    .g_xl_3,
    .gx_xl_3 {
        --bs-gutter-x: 1rem;
    }
    .g_xl_3,
    .gy_xl_3 {
        --bs-gutter-y: 1rem;
    }
    .g_xl_4,
    .gx_xl_4 {
        --bs-gutter-x: 1.5rem;
    }
    .g_xl_4,
    .gy_xl_4 {
        --bs-gutter-y: 1.5rem;
    }
    .g_xl_5,
    .gx_xl_5 {
        --bs-gutter-x: 3rem;
    }
    .g_xl_5,
    .gy_xl_5 {
        --bs-gutter-y: 3rem;
    }
}
@media (min-width: 1400px) {
    .col_xxl {
        flex: 1 0 0%;
    }
    .row_cols_xxl_auto > * {
        flex: 0 0 auto;
        width: auto;
    }
    .row_cols_xxl_1 > * {
        flex: 0 0 auto;
        width: 100%;
    }
    .row_cols_xxl_2 > * {
        flex: 0 0 auto;
        width: 50%;
    }
    .row_cols_xxl_3 > * {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
    .row_cols_xxl_4 > * {
        flex: 0 0 auto;
        width: 25%;
    }
    .row_cols_xxl_5 > * {
        flex: 0 0 auto;
        width: 20%;
    }
    .row_cols_xxl_6 > * {
        flex: 0 0 auto;
        width: 16.66666667%;
    }
    .col_xxl_auto {
        flex: 0 0 auto;
        width: auto;
    }
    .col_xxl_1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }
    .col_xxl_2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }
    .col_xxl_3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .col_xxl_4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
    .col_xxl_5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }
    .col_xxl_6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .col_xxl_7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }
    .col_xxl_8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }
    .col_xxl_9 {
        flex: 0 0 auto;
        width: 75%;
    }
    .col_xxl_10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }
    .col_xxl_11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }
    .col_xxl_12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .offset_xxl_0 {
        margin-left: 0;
    }
    .offset_xxl_1 {
        margin-left: 8.33333333%;
    }
    .offset_xxl_2 {
        margin-left: 16.66666667%;
    }
    .offset_xxl_3 {
        margin-left: 25%;
    }
    .offset_xxl_4 {
        margin-left: 33.33333333%;
    }
    .offset_xxl_5 {
        margin-left: 41.66666667%;
    }
    .offset_xxl_6 {
        margin-left: 50%;
    }
    .offset_xxl_7 {
        margin-left: 58.33333333%;
    }
    .offset_xxl_8 {
        margin-left: 66.66666667%;
    }
    .offset_xxl_9 {
        margin-left: 75%;
    }
    .offset_xxl_10 {
        margin-left: 83.33333333%;
    }
    .offset_xxl_11 {
        margin-left: 91.66666667%;
    }
    .g_xxl_0,
    .gx_xxl_0 {
        --bs-gutter-x: 0;
    }
    .g_xxl_0,
    .gy_xxl_0 {
        --bs-gutter-y: 0;
    }
    .g_xxl_1,
    .gx_xxl_1 {
        --bs-gutter-x: 0.25rem;
    }
    .g_xxl_1,
    .gy_xxl_1 {
        --bs-gutter-y: 0.25rem;
    }
    .g_xxl_2,
    .gx_xxl_2 {
        --bs-gutter-x: 0.5rem;
    }
    .g_xxl_2,
    .gy_xxl_2 {
        --bs-gutter-y: 0.5rem;
    }
    .g_xxl_3,
    .gx_xxl_3 {
        --bs-gutter-x: 1rem;
    }
    .g_xxl_3,
    .gy_xxl_3 {
        --bs-gutter-y: 1rem;
    }
    .g_xxl_4,
    .gx_xxl_4 {
        --bs-gutter-x: 1.5rem;
    }
    .g_xxl_4,
    .gy_xxl_4 {
        --bs-gutter-y: 1.5rem;
    }
    .g_xxl_5,
    .gx_xxl_5 {
        --bs-gutter-x: 3rem;
    }
    .g_xxl_5,
    .gy_xxl_5 {
        --bs-gutter-y: 3rem;
    }
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    outline: 0;
}
  
.modal_dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

.modal_dialog_centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 0.5rem * 2);
}

.modal_content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    outline: 0;
    background-clip: padding-box;
}

.d_flex {
    display: flex !important;
}

/*  TEXT UTILITIES  */
.fs_1 {
    font-size: calc(1.375rem + 1.5vw) !important;
}
.fs_2 {
    font-size: calc(1.325rem + 0.9vw) !important;
}
.fs_3 {
    font-size: calc(1.3rem + 0.6vw) !important;
}
.fs_4 {
    font-size: calc(1.275rem + 0.3vw) !important;
}
.fs_5 {
    font-size: 1.25rem !important;
}
.fs_6 {
    font-size: 1rem !important;
}
.fs_7 {
    font-size: 0.9rem !important;
}
.fs_8 {
    font-size: 0.8rem !important;
}
.fst_italic {
    font-style: italic !important;
}
.fst_normal {
    font-style: normal !important;
}
.fw_lighter {
    font-weight: lighter !important;
}
.fw_light {
    font-weight: 300 !important;
}
.fw_normal {
    font-weight: 400 !important;
}
.fw_medium {
    font-weight: 500 !important;
}
.fw_semibold {
    font-weight: 600 !important;
}
.fw_bold {
    font-weight: 700 !important;
}
.fw_bolder {
    font-weight: bolder !important;
}
.lh_1 {
    line-height: 1 !important;
}
.lh_sm {
    line-height: 1.25 !important;
}
.lh_base {
    line-height: 1.5 !important;
}
.lh_lg {
    line-height: 2 !important;
}
.text_start {
    text-align: left !important;
}
.text_end {
    text-align: right !important;
}
.text_center {
    text-align: center !important;
}
.text_decoration_none {
    text-decoration: none !important;
}
.text_decoration_underline {
    text-decoration: underline !important;
}
.text_decoration_line_through {
    text-decoration: line-through !important;
}
.text_lowercase {
    text-transform: lowercase !important;
}
.text_uppercase {
    text-transform: uppercase !important;
}
.text_capitalize {
    text-transform: capitalize !important;
}
.text_wrap {
    white-space: normal !important;
}
.text_nowrap {
    white-space: nowrap !important;
}
.text_break {
    word-wrap: break-word !important;
    word-break: break-word !important;
}
.text_truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.text_justify  { 
    text-align: justify !important; 
}
/*  END TEXT UTILITIES  */

.container,
.container_fluid,
.container_lg,
.container_md,
.container_sm,
.container_xl,
.container_xxl {
    width: 100%;
    padding-right: calc(var($row-gutter-x) * 0.5);
    padding-left: calc(var($row-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
}

/*      For NPS forms      */
.npsForm_Btn {
    height: 3.0rem !important;
    width:  3.0rem !important;
    border-radius: 25%;
    border-color: transparent;
    margin-left: 0.15rem !important;
    margin-right: 0.15rem !important;
    justify-content: center !important;
    align-items: center !important;
    display: flex;
}
.npsForm_Txt {
    font-size: 1.2rem !important;
    display: inline-block;
    margin: 0px!important;
    padding: 0px !important;
}
@media (min-width: 320px) and (max-width: 480px) {
    .npsForm_Btn {
        height: 1.6rem !important;
        width:  1.6rem !important;
        border-radius: 25%;
        border-color: transparent;
        margin-left: 0.08rem !important;
        margin-right: 0.08rem !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .npsForm_Txt {
        font-size: 1.0rem !important;
        display: inline-block;
        margin: 0px!important;
        padding: 0px !important;
    }
}

@media (min-width: 576px) {
    .container,
    .container_sm {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container,
    .container_md,
    .container_sm {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container,
    .container_lg,
    .container_md,
    .container_sm {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container,
    .container_lg,
    .container_md,
    .container_sm,
    .container_xl {
        max-width: 1140px;
    }
}
@media (min-width: 1400px) {
    .container,
    .container_lg,
    .container_md,
    .container_sm,
    .container_xl,
    .container_xxl {
        max-width: 1320px;
    }
}


.reset {
    animation : none;
    animation-delay : 0;
    animation-direction : normal;
    animation-duration : 0;
    animation-fill-mode : none;
    animation-iteration-count : 1;
    animation-name : none;
    animation-play-state : running;
    animation-timing-function : ease;
    backface-visibility : visible;
    background : 0;
    background-attachment : scroll;
    background-clip : border-box;
    background-color : transparent;
    background-image : none;
    background-origin : padding-box;
    background-position : 0 0;
    background-position-x : 0;
    background-position-y : 0;
    background-repeat : repeat;
    background-size : auto auto;
    border : 0;
    border-style : none;
    border-width : medium;
    border-color : inherit;
    border-bottom : 0;
    border-bottom-color : inherit;
    border-bottom-left-radius : 0;
    border-bottom-right-radius : 0;
    border-bottom-style : none;
    border-bottom-width : medium;
    border-collapse : separate;
    border-image : none;
    border-left : 0;
    border-left-color : inherit;
    border-left-style : none;
    border-left-width : medium;
    border-radius : 0;
    border-right : 0;
    border-right-color : inherit;
    border-right-style : none;
    border-right-width : medium;
    border-spacing : 0;
    border-top : 0;
    border-top-color : inherit;
    border-top-left-radius : 0;
    border-top-right-radius : 0;
    border-top-style : none;
    border-top-width : medium;
    bottom : auto;
    box-shadow : none;
    box-sizing : content-box;
    caption-side : top;
    clear : none;
    clip : auto;
    color : inherit;
    columns : auto;
    column-count : auto;
    column-fill : balance;
    column-gap : normal;
    column-rule : medium none currentColor;
    column-rule-color : currentColor;
    column-rule-style : none;
    column-rule-width : none;
    column-span : 1;
    column-width : auto;
    content : normal;
    counter-increment : none;
    counter-reset : none;
    cursor : auto;
    direction : ltr;
    display : inline;
    empty-cells : show;
    float : none;
    font : normal;
    font-family : inherit;
    font-size : medium;
    font-style : normal;
    font-variant : normal;
    font-weight : normal;
    height : auto;
    hyphens : none;
    left : auto;
    letter-spacing : normal;
    line-height : normal;
    list-style : none;
    list-style-image : none;
    list-style-position : outside;
    list-style-type : disc;
    margin : 0;
    margin-bottom : 0;
    margin-left : 0;
    margin-right : 0;
    margin-top : 0;
    max-height : none;
    max-width : none;
    min-height : 0;
    min-width : 0;
    opacity : 1;
    orphans : 0;
    outline : 0;
    outline-color : invert;
    outline-style : none;
    outline-width : medium;
    overflow : visible;
    overflow-x : visible;
    overflow-y : visible;
    padding : 0;
    padding-bottom : 0;
    padding-left : 0;
    padding-right : 0;
    padding-top : 0;
    page-break-after : auto;
    page-break-before : auto;
    page-break-inside : auto;
    perspective : none;
    perspective-origin : 50% 50%;
    position : static;
    /* May need to alter quotes for different locales (e.g fr) */
    quotes : '\201C' '\201D' '\2018' '\2019';
    right : auto;
    tab-size : 8;
    table-layout : auto;
    text-align : inherit;
    text-align-last : auto;
    text-decoration : none;
    text-decoration-color : inherit;
    text-decoration-line : none;
    text-decoration-style : solid;
    text-indent : 0;
    text-shadow : none;
    text-transform : none;
    top : auto;
    transform : none;
    transform-style : flat;
    transition : none;
    transition-delay : 0s;
    transition-duration : 0s;
    transition-property : none;
    transition-timing-function : ease;
    unicode-bidi : normal;
    vertical-align : baseline;
    visibility : visible;
    white-space : normal;
    widows : 0;
    width : auto;
    word-spacing : normal;
    z-index : auto;
    /* basic modern patch */
    all: initial;
    all: unset;
}

.card_teacher {
    background-color: $gray100;
    border: 1px solid $gray200;
    border-radius: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


.align_baseline {
    vertical-align: baseline !important;
}
.align_top {
    vertical-align: top !important;
}
.align_middle {
    vertical-align: middle !important;
}
.align_bottom {
    vertical-align: bottom !important;
}
.align_text_bottom {
    vertical-align: text-bottom !important;
}
.align_text_top {
    vertical-align: text-top !important;
}
.align_self_auto {
    align-self: auto !important;
}
.align_self_start {
    align-self: flex-start !important;
}
.align_self_end {
    align-self: flex-end !important;
}
.align_self_center {
    align-self: center !important;
}
.align_self_baseline {
    align-self: baseline !important;
}
.align_self_stretch {
    align-self: stretch !important;
}
.float_start {
    float: left !important;
}
.float_end {
    float: right !important;
}
.float_none {
    float: none !important;
}
.justify_content_start {
    justify-content: flex-start !important;
}
.justify_content_end {
    justify-content: flex-end !important;
}
.justify_content_center {
    justify-content: center !important;
}
.justify_content_self_center {
    justify-self: center !important;
}
.justify_content_between {
    justify-content: space-between !important;
}
.justify_content_around {
    justify-content: space-around !important;
}
.justify_content_evenly {
    justify-content: space-evenly !important;
}